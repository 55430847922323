<template>
  <div>
    <skeleton v-if="loading" />
    <purchase-orders-form
      v-else
      ref="purchase-orders-form"
      @refetch="refetch"
    />
  </div>
</template>
<script>
import config from '@/views/settings/orders/purchase-orders/purchaseOrdersConfig'
import purchaseOrdersModule from '@/store/settings/orders/purchase-order'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import PurchaseOrdersForm from '@/views/settings/orders/purchase-orders/components/PurchaseOrdersForm.vue'
import Skeleton from '@/views/settings/orders/purchase-orders/components/Skeleton.vue'

export default {
  name: 'PurchaseOrders',
  components: { PurchaseOrdersForm, Skeleton },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    this.$emit('updateMenu', 'settings-orders-purchase')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['purchase-orders-form']) this.$refs['purchase-orders-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getPurchaseOrders`).then(res => {
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_PURCHASE_SALES_FORM_CLONE`, data)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE, purchaseOrdersFields } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, purchaseOrdersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      purchaseOrdersFields,
    }
  },
}
</script>
