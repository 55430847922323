import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/order/purchase-order'

const Actions = crudActions(endpoint)
const getPurchaseOrders = (ctx, data) => axios.get(`${endpoint}`, data)
const updatePurchaseOrders = (ctx, data) => axios.post(`${endpoint}`, data)
export default {
  ...Actions,
  getPurchaseOrders,
  updatePurchaseOrders,
}
